import React, { useEffect, useCallback, useRef } from 'react';
import styles from 'Custom/ISIBar/ISIBar.module.scss';
import RichText from 'Custom/RichText';
import { useAppContext } from 'Providers/App';
import Plus from 'Images/brand-icons/plus-button.svg';
import { ISI_BAR_CONTENT } from 'Custom/ISIBar/ISIBar.utils';
import { FIXED_ELEMENT_IDS } from 'Utils/hooks/useFocusAlwaysVisible';
import { UI_ELEMENTS } from 'Providers/App/elements';

const { modalISI: modalISIId } = UI_ELEMENTS;

const TIME_OUT_MS = 300;
const SCROLL_THRESHOLD = 5;
const SCROLLING_SIZE_TRIGGER = 991;

let scrollFinished = null;

const ISIBar = ({ content = ISI_BAR_CONTENT, orphan }) => {
  const {
    ARIA,
    indicationTitle,
    indicationMobile,
    indicationIntro,
    isiTitle,
    isiMobile,
    isiIntro,
  } = content;
  const { getElementState, getElementClickHandler } = useAppContext();
  const mainRef = useRef(null);
  const modalISIHandler = getElementClickHandler(modalISIId);
  const [modalISI, isISIVisible] = getElementState(
    modalISIId,
    UI_ELEMENTS.isISIVisible
  );

  const baseClass = !isISIVisible
    ? `${styles.stickyBar}`
    : `${styles.stickyBar} ${styles.hideBar}`;

  const orphanBaseClass =
    !isISIVisible && orphan
      ? `${styles.stickyOrphanBar}`
      : `${styles.stickyBar} ${styles.hideBar}`;

  const showIndication = () => {
    const mainISIContainer = mainRef.current;
    const ellipsisContainer = mainISIContainer.querySelector(
      `.${styles.indicationEllipsis}`
    );
    const indicationButton = mainISIContainer.querySelector(
      `.${styles.mobileIndicationButton}`
    );

    indicationButton.classList.remove(styles.show);
    ellipsisContainer.classList.remove(styles.hide);
  };

  const handleScroll = useCallback((mainISIContainer) => {
    let prevPosition = window.pageYOffset || document.documentElement.scrollTop;
    let isScrollingDown = false;
    const mobileIndication = mainISIContainer.querySelector(
      `.${styles.mobileIndication}`
    );
    const ellipsisContainer = mainISIContainer.querySelector(
      `.${styles.indicationEllipsis}`
    );
    const indicationButton = mainISIContainer.querySelector(
      `.${styles.mobileIndicationButton}`
    );

    return () => {
      clearTimeout(scrollFinished);

      const screenWidth = window.innerWidth;

      if (screenWidth > SCROLLING_SIZE_TRIGGER) {
        return;
      }

      // Scrolling
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      const scrollDistance = Math.abs(currentPosition - prevPosition);

      if (scrollDistance > SCROLL_THRESHOLD) {
        isScrollingDown = currentPosition > prevPosition;

        mainISIContainer.classList.toggle(styles.transparent, isScrollingDown);
        mobileIndication.classList.toggle(styles.hide, isScrollingDown);
        ellipsisContainer.classList.toggle(styles.hide, isScrollingDown);
        indicationButton.classList.remove(styles.show);
      }

      prevPosition = currentPosition;

      // Scrolling stopped
      scrollFinished = setTimeout(function () {
        mainISIContainer.classList.remove(styles.transparent);
        mobileIndication.classList.remove(styles.hide);

        if (!isScrollingDown) {
          indicationButton.classList.remove(styles.show);
          ellipsisContainer.classList.remove(styles.hide);
        } else {
          indicationButton.classList.add(styles.show);
        }
      }, TIME_OUT_MS);
    };
  }, []);

  useEffect(() => {
    if (!mainRef.current) {
      return;
    }

    const onScroll = handleScroll(mainRef.current);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <div className={styles.isiFiller} id="isi-filler" />
      <div ref={mainRef} className={`${styles.mobileISI}`} role="complementary">
        <div className={styles.mobileIndication}>
          <RichText className={styles.indicationEllipsis}>
            {indicationMobile}
          </RichText>
          <button
            onClick={showIndication}
            aria-label={ARIA.openIndication}
            className={styles.mobileIndicationButton}
          >
            <Plus
              data-design-category="indication_vd"
              data-design-type="clickAction"
              data-design-action="Expand"
              data-design-label="Indication"
              data-design-nori_id="ISI Indication"
              data-design-module_name="ISI Indication"
              data-design-module_no="00"
            />
          </button>
        </div>
        <div className={styles.mobileSafety}>
          <RichText className={styles.mobileSafetyText}>{isiMobile}</RichText>
          <button
            className={styles.mobileIsiButton}
            aria-label={ARIA.openISI}
            aria-expanded={modalISI}
            onClick={modalISIHandler}
          >
            <Plus
              data-design-category="isi_ref_vd"
              data-design-type="clickAction"
              data-design-action="Expand"
              data-design-label="Important Safety Information"
              data-design-nori_id="ISI Indication"
              data-design-module_name="ISI Indication"
              data-design-module_no="00"
            />
          </button>
        </div>
      </div>
      <div
        className={orphan ? orphanBaseClass : baseClass}
        role="complementary"
        id={FIXED_ELEMENT_IDS.desktop}
      >
        <div className={styles.wrapper}>
          <div className={styles.isiBarContent}>
            <div className={styles.isiBarCol}>
              <span className={styles.isiBarTitle}>{indicationTitle}</span>
              <RichText>{indicationIntro}</RichText>
            </div>
            <div className={styles.isiBarCol}>
              <button
                className={styles.iconContainer}
                onClick={modalISIHandler}
                aria-expanded={modalISI}
                aria-label="Open full Important Safety Information"
              >
                <Plus
                  data-design-category="isi_ref_vd"
                  data-design-type="clickAction"
                  data-design-action="Expand"
                  data-design-label="Important Safety Information"
                  data-design-nori_id="ISI Indication"
                  data-design-module_name="ISI Indication"
                  data-design-module_no="00"
                />
              </button>
              <span className={styles.isiBarTitle}>{isiTitle}</span>
              <RichText className={styles.isiBlock}>{isiIntro}</RichText>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ISIBar;
