import React from 'react';
import Container from 'Nori/Container';
import styles from 'Custom/ISI/ISI.module.scss';
import RichText from 'Custom/RichText';
import Link from 'next/link';
import { useAppContext } from 'Providers/App';
import { ISI_CONTENT } from 'Custom/ISI/ISI.utils';
import { UI_ELEMENTS } from 'Providers/App/elements';

const { modalExternalLink: modalExternalLinkId } = UI_ELEMENTS;

const ISI = ({
  isModal = false,
  noriId = '',
  moduleTitle = '',
  content = ISI_CONTENT,
  ...props
}) => {
  const { getElementClickHandler } = useAppContext();
  const { INDICATION_SHORT_BLOCK, CONTENT_BLOCK, ISI_REPORT } = content;

  const modalExternalLinkHandler = getElementClickHandler(modalExternalLinkId);

  return (
    <Container className={`${styles.wrapper} ISI_wrapper`} {...props}>
      {!isModal && <RichText>{INDICATION_SHORT_BLOCK}</RichText>}
      <div className={styles.topBlock}>
        <RichText>{CONTENT_BLOCK}</RichText>
      </div>

      <RichText>
        <p className={styles.reportFda}>
          {ISI_REPORT.firstPart}{' '}
          <button
            data-design-type="clickAction"
            data-design-category="content_link_vd"
            data-design-action="Link"
            data-design-label="www.fda.gov/medwatch"
            data-design-nori_id={noriId}
            data-design-module_name={moduleTitle}
            data-design-module_no="00"
            onClick={modalExternalLinkHandler}
            className={styles.poplink}
          >
            www.fda.gov/medwatch
          </button>{' '}
          {ISI_REPORT.secondPart}{' '}
          <Link
            data-design-type="clickAction"
            data-design-category="content_link_vd"
            data-design-action="Link"
            data-design-label="1-800-FDA-1088."
            data-design-nori_id={noriId}
            data-design-module_name={moduleTitle}
            data-design-module_no="00"
            className={styles.phoneLink}
            href="tel:18003321088"
          >
            1-800-FDA-1088.
          </Link>
        </p>
        <ISI_REPORT.Accompanying moduleTitle={moduleTitle} noriId={noriId} />
      </RichText>
    </Container>
  );
};

export default ISI;
