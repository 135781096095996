import { useEffect } from 'react';

export const FOCUS_TRACKING_CLASS = 'focus-in-tracking';

export const FOCUS_CONFIG = {
  offset: 40,
  breakpoint: 1299,
};

export const FIXED_ELEMENT_IDS = {
  mobile: 'isi-sticky-navigation',
  desktop: 'isi-sticky-bar',
};

export const DEFAULT_RECT_ELEMENT = {
  height: 0,
  top: 0,
};

export const useFocusAlwaysVisible = () => {
  const getFixedElementRect = (desktopElement, mobileElement, isDesktop) => {
    const fixedElement = isDesktop ? desktopElement : mobileElement;

    return (
      (fixedElement && fixedElement.getBoundingClientRect()) ||
      DEFAULT_RECT_ELEMENT
    );
  };

  useEffect(() => {
    const fixedDesktopElement = document.getElementById(
      FIXED_ELEMENT_IDS.desktop
    );

    const fixedMobileElement = document.getElementById(
      FIXED_ELEMENT_IDS.mobile
    );

    function handleFocusIn(event) {
      const { bottom: focusElementBottom } =
        event.target.getBoundingClientRect();

      const { height: fixedElementHeight, top: fixedElementTop } =
        getFixedElementRect(
          fixedDesktopElement,
          fixedMobileElement,
          window.innerWidth > FOCUS_CONFIG.breakpoint
        );

      if (fixedElementHeight && focusElementBottom > fixedElementTop) {
        window.scrollBy({
          top: focusElementBottom - fixedElementTop + FOCUS_CONFIG.offset,
          behavior: 'auto',
        });
      }
    }

    const trackedItems = document.getElementsByClassName(FOCUS_TRACKING_CLASS);
    [...trackedItems].forEach((trackedItem) => {
      trackedItem.addEventListener('focusin', handleFocusIn);
    });

    return () => {
      [...trackedItems].forEach((trackedItem) => {
        trackedItem.removeEventListener('focusin', handleFocusIn);
      });
    };
  }, []);

  return;
};

export default useFocusAlwaysVisible;
