import React, {
  createContext,
  useState,
  useReducer,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useRouter } from 'next/router';

import elements, {
  ACTIONS,
  initialElementsState,
  UI_ELEMENTS,
} from './elements';

const AppContext = createContext();
const ITEM_NOT_FOUND = '-1';
const STATE_FIRST_INDEX = 0;
const STATE_MIN_MULTIPLE_KEYS = 1;

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(`AppContext provider is not found`);
  }

  return context;
};

export const AppProvider = ({ children }) => {
  const router = useRouter();
  const triggersRefs = useRef({ lastTrigger: null });
  const [openDropdown, setOpenDropdown] = useState([]);
  const [mobileActiveMenu, setMobileActiveMenu] = useState(0);

  const [elementsState, elementsDispatch] = useReducer(
    elements,
    initialElementsState
  );

  const updateElementState = (elementId, value) => {
    const type =
      value !== undefined
        ? ACTIONS.SET_ELEMENT_STATE
        : ACTIONS.TOGGLE_ELEMENT_STATE;

    elementsDispatch({
      type,
      payload: { elementId, value },
    });
  };

  const registerTrigger = (id, trigger) => {
    triggersRefs.current.lastTrigger = trigger;
    triggersRefs.current[id] = trigger;
  };

  const getLastTrigger = () => {
    return triggersRefs.current.lastTrigger;
  };

  const focusTrigger = (id) => {
    let trigger = triggersRefs.current[id];

    if (!trigger) {
      return;
    }

    if (trigger.dataset.triggerMobileId) {
      updateElementState(UI_ELEMENTS.mobileMenuOpen, true);
    }

    trigger.focus();
  };

  const getElementClickHandler = (elementId, value) => {
    return (event) => {
      if (event?.currentTarget) {
        registerTrigger(elementId, event.currentTarget);
      }

      updateElementState(elementId, value);
    };
  };

  const getElementState = useCallback(
    (...keys) => {
      if (keys.length > STATE_MIN_MULTIPLE_KEYS) {
        return keys.map((key) => elementsState?.[key]);
      }

      return elementsState?.[keys[STATE_FIRST_INDEX]];
    },
    [elementsState]
  );

  const handleOpenDropdown = (id) => {
    if (openDropdown.indexOf(id) == ITEM_NOT_FOUND) {
      setOpenDropdown((currentSection) => [...currentSection, id]);
    } else {
      setOpenDropdown(openDropdown.filter((item) => item !== id));
    }
  };

  const resetOpenDropdown = () => {
    setOpenDropdown([]);
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', resetOpenDropdown);
    return () => {
      router.events.off('routeChangeComplete', resetOpenDropdown);
    };
  }, [router.events]);

  useEffect(() => {
    if (!elementsState.mobileMenuOpen) {
      document.body.classList.remove('overflowHidden');
    }
  }, [elementsState.mobileMenuOpen]);

  return (
    <AppContext.Provider
      value={{
        openDropdown,
        setOpenDropdown,
        handleOpenDropdown,
        mobileActiveMenu,
        setMobileActiveMenu,

        // UI Elements
        getElementState,
        updateElementState,
        getElementClickHandler,

        // Triggers
        getLastTrigger,
        registerTrigger,
        focusTrigger,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
