import React, { isValidElement, useRef } from 'react';
import { GENERAL_JOB_CODE } from 'Custom/Footer/Footer.utils.js';
import BaseLayout from 'Nori/Layout/BaseLayout';
import Footer from 'Custom/Footer';
import Header from 'Custom/Header';
import StickyNavigation from 'Custom/Header/StickyNavigation';
import ISIFooter from 'Custom/ISI/ISIFooter';
import ISIBar from 'Custom/ISIBar';
import ExternalLinkModal from 'Custom/Modal/ExternalLinkModal';
import HealthcareModal from 'Custom/Modal/HealthcareModal';
import ISIModal from 'Custom/Modal/ISIModal';
import ActionStrip from 'Custom/ActionStrip';
import { FOCUS_TRACKING_CLASS } from 'Utils/hooks/useFocusAlwaysVisible';

import { EXTERNAL_LINK_MODAL_CONTENT } from 'Custom/Modal/es/ExternalLinkModal.utils';
import { HEALTHCARE_MODAL_CONTENT } from 'Custom/Modal/es/HealthcareModal.utils';

import { FOOTER_CONTENT } from 'Custom/Footer/es/Footer.utils';
import { HEADER_CONTENT, STICKY_CONTENT } from 'Custom/Header/es/Header.utils';
import { ISI_CONTENT } from 'Custom/ISI/es/ISI.utils';
import { ISI_BAR_CONTENT } from 'Custom/ISIBar/es/ISIBar.utils';
import { ISI_MODAL_CONTENT } from 'Custom/Modal/es/ISIModal.utils';

const PageReference = () => null;
const AltLayout = ({
  reference: Reference = PageReference,
  className = '',
  hideISI = false,
  jobCode = GENERAL_JOB_CODE,
  children,
  ...props
}) => {
  const isiRef = useRef(null);

  return (
    <BaseLayout isiRef={isiRef} {...props}>
      <Header content={HEADER_CONTENT} />
      {!hideISI && <ISIBar content={ISI_BAR_CONTENT} />}
      <StickyNavigation content={STICKY_CONTENT} />

      <div className={FOCUS_TRACKING_CLASS}>
        <main className={className}>{children}</main>
        {!hideISI && isValidElement(<Reference />) && (
          <ISIFooter
            reference={Reference}
            variant={ISI_CONTENT.variant}
            content={ISI_CONTENT}
            ref={isiRef}
          />
        )}

        <ActionStrip
          designModuleNo="00"
          title="VISITA EL SITIO EN INGLÉS PARA MÁS INFORMACIÓN"
          buttonLabel="Sitio en inglés"
          buttonHref="/"
        />
        <Footer jobCode={jobCode} content={FOOTER_CONTENT} />
      </div>

      <ISIModal content={ISI_MODAL_CONTENT} />
      <ExternalLinkModal content={EXTERNAL_LINK_MODAL_CONTENT} />
      <HealthcareModal content={HEALTHCARE_MODAL_CONTENT} />
    </BaseLayout>
  );
};

export default AltLayout;
