export const FOCUSABLE_ELEMENTS_SELECTOR =
  'a[href]:not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]), select:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]):not([tabindex="-1"]), [tabindex]:not([disabled]):not([tabindex="-1"])';

const getFocusableElements = (container) => {
  const focusableElements = container.querySelectorAll(
    FOCUSABLE_ELEMENTS_SELECTOR
  );
  const focusableElementsArray = Array.from(focusableElements);
  return focusableElementsArray;
};

export default getFocusableElements;
