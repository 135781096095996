import { useEffect } from 'react';
import { useAppContext } from 'Providers/App';
import { UI_ELEMENTS } from 'Providers/App/elements';
import getFirstFocusableElement from 'Utils/functions/getFirstFocusableElement';

function useMenuFocusLastTrigger(containerRef, isOpen) {
  const { getLastTrigger, getElementState, mobileActiveMenu } = useAppContext();
  const mobileMainMenu = getElementState(UI_ELEMENTS.mobileMainMenu);

  // Focus after closing a modal
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const lastTrigger = getLastTrigger();

    if (!lastTrigger?.dataset.triggerMobileId) {
      const focusableElement = getFirstFocusableElement(containerRef.current);
      focusableElement.focus();
      return;
    }

    const trigger = containerRef.current.querySelector(
      `[data-trigger-mobile-id="${lastTrigger.dataset.triggerMobileId}"]`
    );

    if (trigger) {
      trigger.focus();
    }
  }, [containerRef, isOpen]);

  // Focus after closing a submenu
  useEffect(() => {
    if (!mobileActiveMenu || mobileMainMenu) {
      return;
    }

    const lastTrigger = getLastTrigger();
    if (!lastTrigger || lastTrigger.id !== 'back-button') {
      return;
    }

    const lastActiveElement = containerRef.current.querySelector(
      `[data-menu-item-id="${mobileActiveMenu}"]`
    );

    if (lastActiveElement) {
      lastActiveElement.focus();
    }
  }, [containerRef, mobileActiveMenu, mobileMainMenu]);
}

export default useMenuFocusLastTrigger;
