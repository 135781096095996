// Unique identifiers to manage UI states (show/hide) and focus order
export const UI_ELEMENTS_DEFAULTS = {
  // Modals
  modalHealthcare: false,
  modalISI: false,
  modalPDF: false,
  modalExternalLink: false,

  // Others
  menuMobile: false,
  mobileMainMenu: false,
  mobileMenuOpen: false,
  isISIVisible: false,
  pageableModalPDF: true,
};

export const UI_ELEMENTS = Object.keys(UI_ELEMENTS_DEFAULTS).reduce(
  (obj, key) => {
    obj[key] = key;
    return obj;
  },
  {}
);

export const initialElementsState = UI_ELEMENTS_DEFAULTS;

export const checkElementId = (elementId) => {
  if (!UI_ELEMENTS.hasOwnProperty(elementId)) {
    console.warn(`The ID "${elementId}" is not defined in UI_ELEMENTS`);
    return false;
  }

  return true;
};

export const ACTIONS = {
  SET_ELEMENT_STATE: 'SET_UI_ELEMENT',
  TOGGLE_ELEMENT_STATE: 'TOGGLE_UI_ELEMENT',
};

const elementsReducer = (state, action) => {
  if (!checkElementId(action.payload.elementId)) {
    return state;
  }

  switch (action.type) {
    case ACTIONS.SET_ELEMENT_STATE:
      if (action.payload.value === state[action.payload.elementId]) {
        return state;
      }

      return {
        ...state,
        [action.payload.elementId]: Boolean(action.payload.value),
      };
    case ACTIONS.TOGGLE_ELEMENT_STATE:
      return {
        ...state,
        [action.payload.elementId]: !state[action.payload.elementId],
      };
    default:
      return state;
  }
};

export default elementsReducer;
