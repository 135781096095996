import React, { useEffect } from 'react';
import DynamicHead from 'Nori/DynamicHead';
import { useAppContext } from 'Providers/App';
import { UI_ELEMENTS } from 'Providers/App/elements';

const BaseLayout = ({ head = {}, hideISI = false, isiRef, children }) => {
  const { updateElementState } = useAppContext();

  const scrollHandler = () => {
    if (!isiRef.current) {
      return;
    }

    const domRect = isiRef.current.getBoundingClientRect();

    const isInViewPort =
      domRect.top <=
      (window.innerHeight || document.documentElement.clientHeight);
    updateElementState(UI_ELEMENTS.isISIVisible, isInViewPort);
  };

  useEffect(() => {
    if (!hideISI) {
      window.addEventListener('scroll', scrollHandler, true);
      scrollHandler();
    }

    const search = new URLSearchParams(window.location.search);
    if (search.get('request') === 'youtube') {
      updateElementState(UI_ELEMENTS.modalISI, true);
    }

    if (search.get('request') === 'labeling') {
      updateElementState(UI_ELEMENTS.modalPDF, true);
      updateElementState(UI_ELEMENTS.pageableModalPDF, false);
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  return (
    <>
      <DynamicHead {...head} />
      {children}
    </>
  );
};

export default BaseLayout;
