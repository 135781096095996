import ContainerXL from 'Nori/Container/ContainerXL';
import Link from 'next/link';
import Image from 'Nori/Image';
import styles from './DesktopMenu.module.scss';
import NavigationLinks from './NavigationLinks';
import Button from 'Nori/Button';
import { useAppContext } from 'Providers/App';
import { finderHandlerClick } from 'Custom/Finder/utils';
import { UI_ELEMENTS } from 'Providers/App/elements';
import BRAND_LOGO from 'Images/logos/main-logo.png';

const { modalHealthcare: modalHealthcareId, modalISI: modalISIId } =
  UI_ELEMENTS;

const DesktopMenu = ({ basic = false }) => {
  const { getElementClickHandler, getElementState } = useAppContext();

  const modalISIHandler = getElementClickHandler(modalISIId);
  const modalHealthcareHandler = getElementClickHandler(modalHealthcareId);
  const [modalISI, modalHealthcare] = getElementState(
    modalISIId,
    modalHealthcareId
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.flexContainer}>
        <div className={styles.logoContainer}></div>
        <div className={styles.navContainer}>
          <div
            className={basic ? styles.topContainerWhite : styles.topContainer}
          />
        </div>
      </div>
      <div className={styles.wrapperContainer}>
        <ContainerXL>
          <nav className={styles.mainNav}>
            <div className={styles.logoWrapper}>
              <Link href="/" className={styles.nexplanonLogo}>
                <Image
                  src={BRAND_LOGO}
                  width={BRAND_LOGO.width}
                  height={BRAND_LOGO.height}
                  layout="intrinsic"
                  alt="NEXPLANON® (etonogestrel implant) 68 mg Radiopaque Logo"
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Logo"
                  data-design-label="Nexplanon logo"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                />
              </Link>
            </div>
            <div className={styles.mainNavContainer}>
              {basic ? (
                <div className={styles.singleNavigation}>
                  <Link
                    href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_pi.pdf"
                    target="_blank"
                    data-design-type="clickAction"
                    data-design-category="top_nav_vd"
                    data-design-action="Download"
                    data-design-label="Prescribing Information"
                    data-design-nori_id="Navigation"
                    data-design-module_name="Navigation"
                    data-design-module_no="00"
                  >
                    Prescribing Information
                  </Link>
                  <Link
                    href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_ppi.pdf"
                    target="_blank"
                    data-design-type="clickAction"
                    data-design-category="top_nav_vd"
                    data-design-action="Download"
                    data-design-label="Patient Information"
                    data-design-nori_id="Navigation"
                    data-design-module_name="Navigation"
                    data-design-module_no="00"
                  >
                    Patient Information
                  </Link>
                </div>
              ) : (
                <>
                  <div className={styles.topNavigation}>
                    <button
                      className={styles.link}
                      onClick={modalISIHandler}
                      data-design-category="top_nav_vd"
                      data-design-type="clickAction"
                      data-design-action="Expand"
                      data-design-label="Important Safety Information"
                      data-design-nori_id="Navigation"
                      data-design-module_name="Navigation"
                      data-design-module_no="00"
                      aria-expanded={modalISI}
                    >
                      Important Safety Information
                    </button>

                    <Link
                      href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_pi.pdf"
                      target="_blank"
                      data-design-type="clickAction"
                      data-design-category="top_nav_vd"
                      data-design-action="Download"
                      data-design-label="Prescribing Information"
                      data-design-nori_id="Navigation"
                      data-design-module_name="Navigation"
                      data-design-module_no="00"
                    >
                      Prescribing Information
                    </Link>
                    <Link
                      href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_ppi.pdf"
                      target="_blank"
                      data-design-type="clickAction"
                      data-design-category="top_nav_vd"
                      data-design-action="Download"
                      data-design-label="Patient Information"
                      data-design-nori_id="Navigation"
                      data-design-module_name="Navigation"
                      data-design-module_no="00"
                    >
                      Patient Information
                    </Link>
                    <button
                      className={styles.link}
                      onClick={modalHealthcareHandler}
                      data-design-type="clickAction"
                      data-design-category="top_nav_vd"
                      data-design-action="Expand"
                      data-design-label="Healthcare Professionals"
                      data-design-nori_id="Navigation"
                      data-design-module_name="Navigation"
                      data-design-module_no="00"
                      aria-expanded={modalHealthcare}
                    >
                      Healthcare Professionals
                    </button>
                    <Link
                      href="/es-us/"
                      target="_blank"
                      data-design-type="clickAction"
                      data-design-category="top_nav_vd"
                      data-design-action="Link"
                      data-design-label="Español"
                      data-design-nori_id="Navigation"
                      data-design-module_name="Navigation"
                      data-design-module_no="00"
                    >
                      Español
                    </Link>
                  </div>
                  <div className={styles.mainMenu}>
                    <NavigationLinks />
                    <div className={styles.ctaContainer}>
                      <Button
                        data-design-type="clickAction"
                        data-design-category="top_nav_vd"
                        data-design-action="Button"
                        data-design-label="Find a Doctor"
                        data-design-nori_id="Navigation"
                        data-design-module_name="Navigation"
                        data-design-module_no="00"
                        className={styles.ctaButton}
                        type="secondary2"
                        size="medium"
                        href="#find-a-doctor"
                        onClick={finderHandlerClick}
                      >
                        Find a Doctor
                      </Button>
                      <Button
                        data-design-type="clickAction"
                        data-design-category="top_nav_vd"
                        data-design-action="Button"
                        data-design-label="My NEXPLANON"
                        data-design-nori_id="Navigation"
                        data-design-module_name="Navigation"
                        data-design-module_no="00"
                        className={styles.ctaButton}
                        href="/enrollment/"
                        type="outlineSecondary2"
                        size="medium"
                      >
                        My NEXPLANON
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </nav>
        </ContainerXL>
      </div>
    </div>
  );
};

export default DesktopMenu;
