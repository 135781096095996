import Container from 'Nori/Container/';
import Link from 'next/link';
import Image from 'Nori/Image';
import styles from './DesktopMenu.module.scss';
import NavigationLinks from './NavigationLinks';
import Button from 'Nori/Button';
import ExternalLink from 'Images/brand-icons/alt-external-link.svg';
import { useAppContext } from 'Providers/App';
import { ARIA, HEADER_CONTENT } from 'Custom/Header/es/Header.utils';
import { UI_ELEMENTS } from 'Providers/App/elements';
import { Links } from 'Custom/Header/es/Header.utils';
import BRAND_LOGO from 'Images/logos/main-logo.png';

const { modalHealthcare: modalHealthcareId, modalISI: modalISIId } =
  UI_ELEMENTS;

const AltDesktopMenu = ({ basic = false }) => {
  const { getElementClickHandler, getElementState } = useAppContext();

  const modalISIHandler = getElementClickHandler(modalISIId);
  const modalHealthcareHandler = getElementClickHandler(modalHealthcareId);
  const [modalISI, modalHealthcare] = getElementState(
    modalISIId,
    modalHealthcareId
  );

  return (
    <div className={`${styles.wrapper} ${styles.alt}`}>
      <div className={styles.flexContainer}>
        <div className={styles.logoContainer}></div>
        <div className={styles.navContainer}>
          <div
            className={basic ? styles.topContainerWhite : styles.topContainer}
          />
        </div>
      </div>
      <div className={styles.wrapperContainer}>
        <Container>
          <nav className={styles.mainNav}>
            <div className={styles.logoWrapper}>
              <Link
                href={HEADER_CONTENT.mainRoute}
                className={styles.nexplanonLogo}
              >
                <Image
                  src={BRAND_LOGO}
                  width={BRAND_LOGO.width}
                  height={BRAND_LOGO.height}
                  layout="intrinsic"
                  alt={ARIA.logo}
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Logo"
                  data-design-label="Logotipo de Nexplanon"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                />
              </Link>
            </div>
            <div className={styles.mainNavContainer}>
              <div className={styles.topNavigation}>
                <button
                  className={styles.link}
                  onClick={modalISIHandler}
                  data-design-category="top_nav_vd"
                  data-design-type="clickAction"
                  data-design-action="Expand"
                  data-design-label="Información de Seguridad Importante"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  aria-expanded={modalISI}
                >
                  <span className={styles.textBlock}>Información de</span>{' '}
                  Seguridad Importante
                </button>

                <Link
                  href="/static/pdf/PI.pdf"
                  target="_blank"
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Download"
                  data-design-label="Información de Prescripción"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                >
                  <span className={styles.textBlock}>Información de</span>{' '}
                  Prescripción
                </Link>
                <Link
                  href="/static/pdf/PPI.pdf"
                  target="_blank"
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Download"
                  data-design-label="Información para el Paciente"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                >
                  <span className={styles.textBlock}>Información para</span> el
                  Paciente
                </Link>
                <button
                  className={styles.link}
                  onClick={modalHealthcareHandler}
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Expand"
                  data-design-label="Profesional de la Salud"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  aria-expanded={modalHealthcare}
                >
                  <span className={styles.textBlock}>Profesional de</span> la
                  Salud
                </button>
                <Link
                  href="/"
                  target="_blank"
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Link"
                  data-design-label="Inglés"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                >
                  Inglés
                </Link>
              </div>
              <div className={styles.mainMenu}>
                <NavigationLinks links={Links} alternative />
                <Button
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Button"
                  data-design-label="Buscar un Doctor"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  className={styles.ctaButton}
                  href="/what-is-nexplanon/#find-a-doctor"
                  target="_blank"
                >
                  Buscar un Doctor
                  <ExternalLink />
                </Button>
              </div>
            </div>
          </nav>
        </Container>
      </div>
    </div>
  );
};

export default AltDesktopMenu;
