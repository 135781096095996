import React from 'react';
import AltLayout from 'Nori/Layout/AltLayout';
import Button from 'Nori/Button';
import ButtonCaption from 'Nori/Button/ButtonCaption';
import FinderSimple from 'Custom/Finder/FinderSimple';
import Container from 'Nori/Container';
import Image from 'Nori/Image';
import SplitContent from 'Custom/Split/SplitContent';
import AjustableImage from 'Custom/AjustableImage';
import RichText from 'Custom/RichText';
import FAQ from 'Custom/FAQ';
import FAQHeader from 'Custom/FAQ/FAQHeader';
import Hero from 'Custom/Hero';
import { FAQ_HOME_QUESTIONS } from 'Utils/es/questions';
import { SPANISH_JOB_CODE } from 'Custom/Footer/es/Footer.utils';

import styles from 'styles/pages/home.module.scss';

import HAND_IMAGE from 'Images/misc/hand.webp';
import HERO_WOMAN_IMAGE from 'Images/people/hero-spanish-caption.webp';
import HERO_WOMAN_IMAGE_MOBILE from 'Images/people/hero-spanish-caption-mobile.webp';
import CIRCLE_IMAGE from 'Images/misc/circle-arrows-yellow.webp';
import EFFECTIVE_IMAGE from 'Images/misc/eficacia-superior.webp';
import EFFECTIVE_IMAGE_MOBILE from 'Images/misc/eficacia-superior-mobile.webp';
import HAND_SHORT_IMAGE from 'Images/misc/hand-shadow-faq.webp';
import ArrowDown from 'Images/brand-icons/arrow-down.svg';

const HEAD = {
  title: 'NEXPLANON® (etonogestrel implant) 68 mg Radiopaque Sitio Oficial',
  keywords: 'nexplanon',
  description:
    'Encuentre información sobre NEXPLANON® (etonogestrel implant) 68 mg Radiopaque, incluida la eficacia, cómo funciona, preguntas frecuentes y efectos secundarios.',
  hreflang: 'es-us',
};

const HERO_IMAGE = {
  src: HERO_WOMAN_IMAGE,
  width: HERO_WOMAN_IMAGE.width / 1.5,
  height: HERO_WOMAN_IMAGE.height / 1.5,
  alt: 'Actor portrayal',
};

const HERO_IMAGE_MOBILE = {
  src: HERO_WOMAN_IMAGE_MOBILE,
  width: HERO_WOMAN_IMAGE_MOBILE.width / 1.5,
  height: HERO_WOMAN_IMAGE_MOBILE.height / 1.5,
  alt: 'Actor portrayal',
};

export default function HomeSpanish() {
  return (
    <AltLayout head={HEAD} jobCode={SPANISH_JOB_CODE}>
      <Hero
        title={
          <>
            CONOZCA <strong>NEXPLANON,</strong>
          </>
        }
        background="primaryLight"
        variant="mainSpanish"
        image={HERO_IMAGE}
        mobileImage={HERO_IMAGE_MOBILE}
        imageBreakpoint="lg"
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Hero"
        data-design-module_name="CONOZCA NEXPLANON"
        data-design-module_no="01"
      >
        <h2>
          el implante anticonceptivo <br />
          <span>que va en el brazo</span>
        </h2>
        <Button
          href="/es-us/efectos-secundarios/"
          data-design-type="clickAction"
          data-design-category="button_vd"
          data-design-action="Button"
          data-design-label="Efectos Secundarios"
          data-design-nori_id="Hero"
          data-design-module_name="CONOZCA NEXPLANON"
          data-design-module_no="01"
        >
          Efectos Secundarios
        </Button>
        <h3>
          ¿ya usas <strong>NEXPLANON</strong>?
        </h3>
        <p>
          Haz un repaso de lo último sobre NEXPLANON y lo que viene después.
        </p>

        <Button
          type="outlineSecondary"
          size="small"
          href="/es-us/preguntas/"
          data-design-type="clickAction"
          data-design-category="button_vd"
          data-design-action="Button"
          data-design-label="Preguntas"
          data-design-nori_id="Hero"
          data-design-module_name="CONOZCA NEXPLANON"
          data-design-module_no="01"
        >
          Preguntas
        </Button>

        <div className={styles.moreInformation}>
          <Button
            type="simple"
            size="small"
            href="#nexplanon-funciona-previniendo-la-ovulacion"
            data-design-type="clickAction"
            data-design-category="content_link_vd"
            data-design-action="Link-Anchor"
            data-design-label="Más información"
            data-design-nori_id="Hero"
            data-design-module_name="CONOZCA NEXPLANON"
            data-design-module_no="01"
          >
            <ArrowDown /> Más información
          </Button>
        </div>
      </Hero>

      <SplitContent
        id="nexplanon-funciona-previniendo-la-ovulacion"
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Inline Content"
        data-design-module_name="NEXPLANON actúa impidiendo la ovulación"
        data-design-module_no="02"
      >
        <AjustableImage
          caption="el implante que se muestra no es de tamaño real"
          captionSize="small"
          image={{
            src: HAND_IMAGE,
            alt: "Visual of the Size of an Implant Relative to a Person's Hand",
          }}
        />
        <>
          <RichText>
            <h2>NEXPLANON actúa impidiendo la ovulación</h2>
            <p className="intro">
              NEXPLANON actúa utilizando una hormona que impide que el óvulo sea
              liberado por tu ovario y evita que los espermatozoides lleguen al
              óvulo. También modifica el revestimiento del útero.
            </p>
          </RichText>
          <ButtonCaption
            href="/what-is-nexplanon/"
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Button"
            data-design-label="Muéstrame cómo"
            data-design-nori_id="Inline Content"
            data-design-module_name="NEXPLANON actúa impidiendo la ovulación"
            data-design-module_no="02"
            caption="sitio en inglés"
          >
            Muéstrame cómo
          </ButtonCaption>
        </>
      </SplitContent>

      <SplitContent
        reverse
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Inline Content"
        data-design-module_name="NEXPLANON es tan efectivo como la píldora, sin las molestias diarias"
        data-design-module_no="03"
      >
        <AjustableImage
          size="xl"
          background="secondary2Light"
          imageMobile={{
            src: EFFECTIVE_IMAGE_MOBILE,
            alt: 'Over 99% Effective: Less Than 1 Pregnancy per 100 Women Who Used NEXPLANON® (etonogestrel implant) 68 mg Radiopaque for 1 Year',
          }}
          image={{
            src: EFFECTIVE_IMAGE,
            alt: 'Over 99% Effective: Less Than 1 Pregnancy per 100 Women Who Used NEXPLANON® (etonogestrel implant) 68 mg Radiopaque for 1 Year',
          }}
        />
        <>
          <RichText>
            <h2>
              NEXPLANON es tan efectivo como la píldora, sin las molestias
              diarias
            </h2>
            <p className="intro">
              Este anticonceptivo no lo tienes que tomar todos los días. Es un
              implante pequeño y flexible que previene el embarazo por hasta 3
              años.*
            </p>
            <p>*NEXPLANON se debe extraer al final del tercer año.</p>
          </RichText>
          <ButtonCaption
            type="secondary"
            href="/effectiveness/"
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Button"
            data-design-label="Conoce los detalles"
            data-design-nori_id="Inline Content"
            data-design-module_name="NEXPLANON es tan efectivo como la píldora, sin las molestias diarias"
            data-design-module_no="03"
            caption="sitio en inglés"
          >
            Conoce los detalles
          </ButtonCaption>
        </>
      </SplitContent>

      <section
        className={`${styles.splitAccordion} ${styles.spanishSplitAccordion}`}
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Accordion"
        data-design-module_name="Preguntas para discutir con su médico"
        data-design-module_no="04"
      >
        <Container>
          <div className={styles.splitBlock}>
            <div className={styles.content}>
              <RichText>
                <h3 className={`h4 ${styles.commonQuestionsTitle}`}>
                  PREGUNTAS FRECUENTES
                </h3>

                <h2>Preguntas para discutir con su médico</h2>

                <p className="intro">
                  Hable con su médico sobre los riesgos y beneficios de
                  NEXPLANON.
                </p>
              </RichText>
              <div className={styles.ctaWrapper}>
                <Button
                  type="primary"
                  target="_blank"
                  href="/assets/static/Nexplanon-FAQ-spanish.pdf"
                  data-design-type="clickAction"
                  data-design-category="button_vd"
                  data-design-action="Download"
                  data-design-label="Descargar Preguntas"
                  data-design-nori_id="Accordion"
                  data-design-module_name="Preguntas para discutir con su médico"
                  data-design-module_no="04"
                >
                  Descargar Preguntas
                </Button>
                <Button
                  type="outlineSecondary2"
                  href="/es-us/preguntas/"
                  data-design-type="clickAction"
                  data-design-category="button_vd"
                  data-design-action="Button"
                  data-design-label="Preguntas"
                  data-design-nori_id="Accordion"
                  data-design-module_name="Preguntas para discutir con su médico"
                  data-design-module_no="04"
                >
                  Preguntas
                </Button>
              </div>
            </div>
            <div className={styles.accordion}>
              <FAQ allowMultiple={true}>
                {FAQ_HOME_QUESTIONS.map((item, index) => (
                  <React.Fragment key={`question-${index}`}>
                    <FAQHeader
                      data-design-type="clickAction"
                      data-design-label={item.title}
                      data-design-nori_id="Accordion"
                      data-design-module_name="Preguntas para discutir con su médico"
                      data-design-module_no="04"
                      data-design-element_no={`${
                        index < 9 ? '0' + (index + 1) : index + 1
                      }`}
                    >
                      {item.title}
                    </FAQHeader>
                    <div className={styles.faqContent}>{item.content}</div>
                  </React.Fragment>
                ))}
              </FAQ>
            </div>
            <p className={styles.caption}>
              el implante que se muestra no es de tamaño real
            </p>
            <div className={styles.handImageWrapper}>
              <Image
                className={styles.handImage}
                src={HAND_SHORT_IMAGE}
                width={HAND_SHORT_IMAGE.width}
                height={HAND_SHORT_IMAGE.height}
                alt=""
              />
            </div>
          </div>
        </Container>
      </section>

      <FinderSimple
        designModuleNo="05"
        title="¿lista para NEXPLANON?"
        buttonLabel="Encuentra un médico"
        buttonHref="/what-is-nexplanon/#find-a-doctor"
      >
        Encuentra un profesional de la salud capacitado e inicia la
        conversación.
      </FinderSimple>

      <SplitContent
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Inline Content"
        data-design-module_name="es reversible"
        data-design-module_no="06"
      >
        <AjustableImage
          background="secondary4Light"
          image={{ src: CIRCLE_IMAGE }}
        />
        <>
          <RichText>
            <h2>es reversible</h2>
            <p className="intro">
              Si usted cambia de planes, su profesional de la salud lo puede
              extraer en cualquier momento durante el período de 3 años.
            </p>
          </RichText>
          <ButtonCaption
            type="secondary4"
            href="/removal/"
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Button"
            data-design-label="Más información sobre la extracción"
            data-design-nori_id="Inline Content"
            data-design-module_name="es reversible"
            data-design-module_no="06"
            caption="sitio en inglés"
            breakCaptionOnMobile
          >
            Más información sobre la extracción
          </ButtonCaption>
        </>
      </SplitContent>
    </AltLayout>
  );
}
